import React from "react"
import Fade from "react-awesome-reveal"

import DominoCardImage from "../../images/domino-card.jpg"

const FeatureSection = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <Fade bottom cascade>
        <div className="max-w-7xl mx-auto mt-10 flex text-white xxs:flex-col sm:flex-row">
          <div className="h-full object-cover overflow-hidden rounded-xl m-5">
            <img src={DominoCardImage}></img>
          </div>
          <div className="m-5">
            <h1 className="text-5xl font-bold font-poppins xxs:text-lg sm:text-5xl">
              Create an online fundraising domino card game
            </h1>
            <p className="font-poppins text-white font-semibold text-2xl leading-10 xxs:text-xs xxs:font-normal sm:text-2xl">
              Set up your club with Fundationary and start raising funds today.
              <br></br>
              Create your game, invite your community, and leave the rest to us.
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 px-8">
          <h1 className="text-white text-7xl font-bold xxs:text-2xl xs:text-3xl sm:text-5xl lg:text-7xl">
            Create your community space today
          </h1>
        </div>
      </Fade>
      <Fade bottom cascade>
        <div className="mt-10 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl">
              Sign up
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Register your club on Fundationary, so your community can find you.
            </p>
          </div>

          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl">
              Create your game
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Set your game type, your entry price and prize.
              You can offer custom prizes as well as a share of the cash.
            </p>
          </div>
          <div className="w-1/3 bg-lightblack p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-1/3">
            <h1 className="text-white text-2xl">
              Spread the word
            </h1>
            <p className="mt-10 text-white opacity-50 text-sm">
              Invite your community via links that you can use on
              WhatsApp groups, email, or via your club noticeboard
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default FeatureSection
